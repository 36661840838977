//Canela Font
@font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-Thin-Trial.otf") format("opentype");
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-Light-Trial.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-Regular-Trial.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-Medium-Trial.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-Bold-Trial.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-Black-Trial.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-ThinItalic-Trial.otf") format("opentype");
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-LightItalic-Trial.otf") format("opentype");
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-RegularItalic-Trial.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-MediumItalic-Trial.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-BoldItalic-Trial.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Canela";
    src: url("./CanelaFamily/Canela-BlackItalic-Trial.otf") format("opentype");
    font-weight: 600;
    font-style: italic;
  }
  
  //Heveria Font
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-Hairline-Trial.otf") format("opentype");
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-Thin-Trial.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-Light-Trial.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-Regular-Trial.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-Medium-Trial.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-Bold-Trial.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-Heavy-Trial.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-HairlineItalic-Trial.otf")
      format("opentype");
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-ThinItalic-Trial.otf")
      format("opentype");
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-LightItalic-Trial.otf")
      format("opentype");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-RegularItalic-Trial.otf")
      format("opentype");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-MediumItalic-Trial.otf")
      format("opentype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-BoldItalic-Trial.otf")
      format("opentype");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Heveria";
    src: url("./Heveria-Trial/OTF/Heveria-HeavyItalic-Trial.otf")
      format("opentype");
    font-weight: 700;
    font-style: italic;
  }
  